import React from "react";
import { Link }  from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="container page-not-found page-default">
      <div className="text-center text-lg-start">
        <h1 className="display-2 pt-5">404: Page Not Found</h1>
        <div className="page-not-found-description">
          <p className="fs-5">
            Sorry, the page you are looking for does not exist. Please use the navigation above to search for your desired webpage.
          </p>
          <p className="fs-5">
            Additionally, click <Link to="/">here</Link> to traverse back to the main page.
          </p>
        </div>
      </div>
    </section>
    
  </Layout>
)

export default NotFoundPage
